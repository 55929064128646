import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import 'trix/dist/trix'
import Chartkick from 'chartkick'
import Highcharts from 'highcharts'
import 'trix'
import 'actiontext'
import 'video.js'
import 'cropperjs'

import DataModule from 'highcharts/modules/data';
import ExportingModule from 'highcharts/modules/exporting';
import OfflineExportingModule from 'highcharts/modules/offline-exporting';
import MapModule from 'highcharts/modules/map';

import '../new/controllers';

DataModule(Highcharts)
ExportingModule(Highcharts)
OfflineExportingModule(Highcharts)
MapModule(Highcharts)

Turbolinks.start()
Chartkick.use(Highcharts)
if (import.meta.env.DEV) Rails.start()

window.Highcharts = Highcharts;
